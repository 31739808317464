/* Colors */
body,
html {
  background-color: #5cbdbb !important;
}

.app {
  height: 93.5vh !important;
}

.sidebar {
  position: fixed !important;
  left: 0 !important;
  height: 100vh !important;
}

p {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 6em;
}

p > span {
  opacity: 0.4;
  text-align: center;
}

#root > div {
  width: 100% !important;
}

/* UI elements */
.ui.grid {
  margin: 0 !important;
  width: 90% !important;
  position: relative !important;
  right: 0em !important;
  /* height: 95.5vh !important; */
}

.ui.grid > .row {
  position: relative;
  /* left: 7em !important; */
  width: 100% !important;
  padding: 1rem 0;
}

.ui.menu {
  position: relative;
  z-index: 101;
  margin: 0 !important;
  width: 100% !important;
  border: 2px solid white !important;
}

.ui.large.vertical.menu {
  height: 100%;
  margin: 0 !important;
  border: 2px solid white !important;
}

#root > div > div > div > div > div:nth-child(1) > div.ui.large.inverted.vertical.menu > div.ui.grid {
  position: relative;
  left: 0;
}

@media only screen and (min-width: 1200px) {
  /* Grid container */

  .ui.grid > .row {
    position: relative;
    left: 7em !important;
    width: 100% !important;
    padding: 1rem 0;
  }

  .ui.container {
    width: 100% !important;
    margin: auto !important;
  }

  /* Column 1 (Sidebar) */
  #root > div > div > div > div > div:nth-child(1) > div.ui.large.inverted.vertical.left.fixed.menu {
    position: relative !important;
    width: 100%;
  }

  #root
    > div
    > div
    > div
    > div
    > div:nth-child(1)
    > div.ui.large.inverted.vertical.left.fixed.menu
    > div.ui.grid
    > div
    > div
    > h4 {
    padding: 0.25em;
  }

  /* Column 2 (Content) */
  #root > div > div > div > div > div:nth-child(2) > div {
    position: relative !important;
  }

  /* Column 3 (Metapanel) */
  #root > div > div > div > div > div:nth-child(3) > div {
    margin-left: 0 !important;
  }

  .ui.styled.accordion {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1200px) {
  /* Grid container */
  .ui.container {
    width: 100% !important;
    margin: auto !important;
  }

  .ui.grid > .row {
    position: relative;
    left: 8em !important;
  }

  /* Column 1 (Sidebar) */
  #root > div > div > div > div > div:nth-child(1) {
    display: flex; /* Show sidebar on tablet */
    flex-direction: column;
  }

  #root > div > div > div > div > div:nth-child(1) > div.ui.large.inverted.vertical.left.fixed.menu > div.ui.grid {
    margin: 0.5em !important;
    width: 100%;
  }

  .message__form {
    position: relative !important;
    margin-left: 0 !important;
    width: 100%; /* Span across entire width */
  }

  /* Column 2 (Content) */
  #root > div > div > div > div > div:nth-child(2) > div {
    margin-left: 0 !important;
  }

  /* Column 3 (Metapanel) */
  #root > div > div > div > div > div:nth-child(3) {
    margin-left: 0 !important;
  }

  .ui.styled.accordion {
    width: 100% !important;
  }
}

@media (max-width: 967px) {

  .app {
    height: 96vh !important;
  }

  /* Sidebar */
  .sidebar.menu {
    /* Hide sidebar on mobile */
    display: none !important; 
  }
  
  .ui.grid > .row {
    position: relative;
    left: 0em !important;
  }

  .ui.grid {
    margin: 0 !important;
    width: 100% !important;
    right: 0em !important;
  }
}
